import { Suspense, useEffect, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from 'react-hot-toast';
import './App.css';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SidebarLayout from './components/Sidebar/SidebarLayout';
import PulseLoader from "./components/PulseLoader";


const PageNotFound = lazy(() => import('./views/Others/PageNotFound'));
const Login = lazy(() => import('./views/Others/Login'));
const Dashboard = lazy(() => import('./views/Home/Dashboard'));

const GeneratePDF = lazy(() => import("./views/Estimate//PDF/GeneratePDF"));



const { userGetAll } = require('./actions/authActions');
const { getSiteSetting } = require('./actions/siteSettingActions');
const { getHereAboutUs } = require('./actions/hereAboutUsAction');
const { getTypeOfProperty } = require('./actions/typeOfPropertyActions');
const { getScopeOfWork } = require('./actions/scopeOfWorkActions');
const { getPropertyUsage } = require('./actions/propertyUsageActions');
const { getSiteType } = require('./actions/siteTypeActions');
const { fetchEstimate } = require('./actions/estimateActions');
const { getPhotos } = require('./actions/photoActions');
const { getInstallList } = require('./actions/installListActions');
const { getRemovalList } = require('./actions/removalListActions');
const { getSubdivision } = require('./actions/subdivisionActions');
const { getPrompt } = require('./actions/promptActions');
const { fetchPdfData } = require('./actions/pdfActions');
const { fetchCoverPhoto } = require('./actions/coverPhotoActions');
const { fetchWhyUsPhoto } = require('./actions/whyUsPhotoActions');
const { fetchWhatToExpect } = require('./actions/whatToExpectPhotoActions');
const { fetchTimeline } = require('./actions/timelineActions');

function App() {

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.Auth);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(userGetAll());
      dispatch(getSiteSetting());
      dispatch(getHereAboutUs());
      dispatch(getTypeOfProperty());
      dispatch(getScopeOfWork());
      dispatch(getPropertyUsage());
      dispatch(getSiteType());
      dispatch(fetchEstimate());
      dispatch(getPhotos());
      dispatch(getInstallList());
      dispatch(getRemovalList());
      dispatch(getSubdivision());
      dispatch(getPrompt());
      dispatch(fetchPdfData());
      dispatch(fetchCoverPhoto());
      dispatch(fetchWhyUsPhoto());
      dispatch(fetchWhatToExpect());
      dispatch(fetchTimeline());
    }

  }, [isAuthenticated])// eslint-disable-line

  return (
    <>
      <Router>
        <Suspense fallback={<PulseLoader />}>
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              <Routes>
                <Route element={<SidebarLayout />} >
                  <Route path="/" element={<ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>} />
                  <Route path="/estimate/generate-pdf/:id" element={<GeneratePDF />} />
                </Route>

                <Route path="/login" element={<Login />} />
                <Route path="*" element={<PageNotFound />} />

              </Routes>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
          </div>
        </Suspense>
        <Toaster
          position="top-center"
          reverseOrder={true}
        />
      </Router>
    </>
  );
}

export default App;

